import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menuoverlay', 'offcanvasmenu', 'closebutton', 'offcanvas']

  open(){
    this.offcanvasTarget.classList.remove('hidden')
    this.menuoverlayTarget.classList.add('opacity-100')
    this.offcanvasmenuTarget.classList.add('translate-x-0')
    this.closebuttonTarget.classList.add('opacity-100')
    this.menuoverlayTarget.classList.remove('opacity-0')
    this.offcanvasmenuTarget.classList.remove('-translate-x-full')
    this.closebuttonTarget.classList.remove('opacity-0')
    console.log('click')
  }

  close(){
    this.menuoverlayTarget.classList.remove('opacity-100')
    this.offcanvasmenuTarget.classList.remove('translate-x-0')
    this.closebuttonTarget.classList.remove('opacity-100')
    this.offcanvasTarget.classList.add('hidden')
  }
}
