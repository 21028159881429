import { Controller } from 'stimulus'

export default class extends Controller {

  initialize() {
    this.hide()
  }

  connect() {
    setTimeout(() => {
      this.show()
    }, 200)
  }

  close() {
    this.hide()
    setTimeout(() => {
      this.element.remove()
    }, 1100)

  }

  show() {
    this.element.classList.remove('translate-y-2')
    this.element.classList.remove('opacity-0')
    this.element.classList.remove('sm:translate-y-0')
    this.element.classList.remove('sm:translate-x-2')
    this.element.classList.add('translate-y-0')
    this.element.classList.add('opacity-100')
    this.element.classList.add('sm:translate-x-0')
    setTimeout(() => {
      this.close();
    }, 10000);
  }

  hide() {
    this.element.classList.remove('translate-y-0')
    this.element.classList.remove('opacity-100')
    this.element.classList.remove('sm:translate-x-0')
    this.element.classList.add('translate-y-2')
    this.element.classList.add('opacity-0')
    this.element.classList.add('sm:translate-y-0')
    this.element.classList.add('sm:translate-x-2')
  }
}
