import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['openbutton', 'closebutton', 'info']

  open(){
    this.openbuttonTarget.classList.add('hidden')
    this.closebuttonTarget.classList.remove('hidden')
    this.infoTarget.classList.remove('hidden')
  }

  close(){
    this.openbuttonTarget.classList.remove('hidden')
    this.closebuttonTarget.classList.add('hidden')
    this.infoTarget.classList.add('hidden')
  }

}
