import { Controller } from "stimulus"
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ['tooltip']
  display() {
    this.tooltipTarget.setAttribute('data-show', '');
    createPopper(this.element, this.tooltipTarget, {
    placement: 'top',
    });
  }

  hide(){
    this.tooltipTarget.removeAttribute('data-show');
  }
}
