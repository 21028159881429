import { Controller } from "stimulus"
import videojs from "video.js";
export default class extends Controller {
  static targets = ['player']
  static values = { url:String, id:String }


  connect() {
    registerIVSTech(videojs);
    //Initialize player
    let player = videojs(this.playerTarget, {
        techOrder: ["AmazonIVS", "html5"],
        liveui: true
    }, () => {

        player.src(this.urlValue);
        console.log(player)
    });

    //if (IVSPlayer.isPlayerSupported) { this.createPlayer() }


  }

  createPlayer() {
    console.log('Is creating player')
    let player = IVSPlayer.create();
    let PlayerState = IVSPlayer.PlayerState;
    let PlayerEventType = IVSPlayer.PlayerEventType;
    player.attachHTMLVideoElement(this.playerTarget);

    for (let state of Object.values(PlayerState)) {
            player.addEventListener(state, () => {
                console.log(state);
            });
        }

    player.addEventListener(PlayerEventType.INITIALIZED, () => {
        console.log('INITIALIZED');
    });

    player.addEventListener(PlayerEventType.ERROR, (e) => {
        if( e.code === 404 ) {
          console.log('Video not avalible')
        } else {
          player.setAutoplay(true);
          player.play();
        }
    });

    this.startPlayer(player)
  }

  startPlayer(player){
    player.setAutoplay(true);
    player.load(this.urlValue);
    player.play();
  }
}
