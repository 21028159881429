import { Controller } from "stimulus"

export default class extends Controller {
static targets = ['webquantity', 'websize', 'workerquantity', 'workersize', 'redis', 'postgresql', 'input', 'output', 'dbrequest', 'scalebutton']
static values = {participants: Number}


  update(){
    let value = this.inputTarget.value;
    this.outputTarget.innerHTML = value
    this.webservers(value)
    this.redisdb(value)
    this.db(value)
    this.scalebuttonTarget.dataset.participants = value
  }

  webservers(value){
    if(value < 1000) {
      this.websizeTarget.innerHTML = 'Standard 1 X'
      let quanitity = Math.round(value/100) + 1
      this.webquantityTarget.innerHTML = quanitity
      this.workersizeTarget.innerHTML = 'Standard 1 X'
      let worker = Math.round((value/600) +1)
      this.workerquantityTarget.innerHTML = worker
      this.scalebuttonTarget.dataset.webquantity = quanitity
      this.scalebuttonTarget.dataset.workerquantity = worker
      this.scalebuttonTarget.dataset.websize="standard-1X"
      this.scalebuttonTarget.dataset.workersize="standard-1X"
    } else {
      this.websizeTarget.innerHTML = 'Standard 2 X'
      let quanitity = Math.round(value/190) + 1
      this.webquantityTarget.innerHTML = quanitity
      this.workersizeTarget.innerHTML = 'Standard 2 X'
      let worker = Math.round((value/700) +1)
      this.workerquantityTarget.innerHTML = worker
      this.scalebuttonTarget.dataset.webquantity = quanitity
      this.scalebuttonTarget.dataset.workerquantity = worker
      this.scalebuttonTarget.dataset.websize="standard-2X"
      this.scalebuttonTarget.dataset.workersize="standard-2X"
    }
  }

  db(value){
    if (value < 100) {
      this.postgresqlTarget.innerHTML = 'hobby-dev'
      this.dbrequestTarget.dataset.dbsize = 'hobby-dev'
    } else if (value > 100 && value < 500 ) {
      this.postgresqlTarget.innerHTML = 'standard-0'
      this.dbrequestTarget.dataset.dbsize = 'standard-0'
    } else if (value > 500 ){
      this.postgresqlTarget.innerHTML = 'standard-2'
      this.dbrequestTarget.dataset.dbsize = 'standard-2'
    }
  }

  redisdb(value){
    if(value < 100){
      this.redisTarget.innerHTML = 30
      this.scalebuttonTarget.dataset.redis = 30
    } else if (value > 100 && value < 250){
      this.redisTarget.innerHTML = 100
      this.scalebuttonTarget.dataset.redis = 100
    } else if (value > 250 && value < 500) {
      this.redisTarget.innerHTML = 500
      this.scalebuttonTarget.dataset.redis = 500
    } else if (value > 500 && value < 1000) {
      this.redisTarget.innerHTML = 1000
      this.scalebuttonTarget.dataset.redis = 1000
    } else if (value > 1000 ){
      this.redisTarget.innerHTML = 2500
      this.scalebuttonTarget.dataset.redis = 2500
    }
  }
}
