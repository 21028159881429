import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['provider', 'aws', 'field', 'vimeo']

  select_provider(e) {
    let selected_provider = e.target.options[e.target.options.selectedIndex].text
    switch (selected_provider) {
      case 'Amazon':
        this.setAmazonFields();
        break;
      case 'Vimeo':
        this.setVimeoFields();
        break;
      default:
        break;
    }
  }

  setAmazonFields() {
    console.log('Amazon Selected')
    this.resetForm();
    this.awsTargets.forEach(item => item.classList.remove('hidden'))
  }

  setVimeoFields() {
    console.log('Vimeo Selected')
    this.resetForm();
    this.vimeoTargets.forEach(item => item.classList.remove('hidden'))
  }

  resetForm(){
    this.fieldTargets.forEach(item => item.classList.add('hidden'))

  }

}
