import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['text', 'tooltip', 'input']
  static values = {message:String}
  copy(){
    let text = this.textTarget.innerHTML
    console.log(this.messageValue)
    this.inputTarget.setAttribute('value', this.messageValue)

    this.inputTarget.select();
    document.execCommand("copy");

    this.textTarget.innerHTML = "Copied!";

  }

  replaceText(){
    this.textTarget.innerHTML = this.messageValue
  }

}
