import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['openbutton', 'closebutton', 'mobilemenu']



  toggle() {
    this.openbuttonTarget.classList.toggle('block')
    this.openbuttonTarget.classList.toggle('hidden')
    this.closebuttonTarget.classList.toggle('block')
    this.closebuttonTarget.classList.toggle('hidden')
    this.mobilemenuTarget.classList.toggle('hidden')
  }
}
