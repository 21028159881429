import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['openbutton', 'closebutton', 'form']

  toggle(){
    this.openbuttonTarget.classList.toggle('hidden')
    this.closebuttonTarget.classList.toggle('hidden')
    this.formTarget.classList.toggle('hidden')
  }
}
